import PropTypes from 'prop-types';
import { LETTER_STATUSES } from 'siteconfig';

import {
  ISODateValidator,
  ISODateValidatorRequired,
} from 'utilities/proptype-validators';
import {
  PAYMENT_TYPES,
  PAYMENT_METHOD_PAYMENT_TYPES,
} from 'utilities/stripe/config';
import { TARGET_TYPES_SHORT } from 'utilities/targets';

const SanityImageType = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

const SanityMerchImageType = PropTypes.shape({
  altText: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

const BlogPostDetailType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  publishedAt: ISODateValidator,
  lastUpdated: ISODateValidator,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mainImage: SanityImageType,
  body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  featuredKeyword: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.exact({ name: PropTypes.string.isRequired })
  ),
  category: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
}).isRequired;

const BlogPostTileType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  publishedAt: ISODateValidator,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mainImage: SanityImageType,
  category: PropTypes.string.isRequired,
}).isRequired;

const LetterType = PropTypes.exact({
  headline: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  pub_date: PropTypes.string.isRequired,
});
const NewspapersType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(LetterType).isRequired,
  })
);
const SiteStatsType = PropTypes.exact({
  lettersSent: PropTypes.number.isRequired,
  recurringDonors: PropTypes.number.isRequired,
  targetRecurringDonors: PropTypes.number.isRequired,
  users: PropTypes.number.isRequired,
  voterRegistrations: PropTypes.number.isRequired,
});

const PetitionType = PropTypes.exact({
  petition_code: PropTypes.string.isRequired,
  petitioner_code: PropTypes.string,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string,
  petitioner_name: PropTypes.string.isRequired,
  state: PropTypes.string,
  sent_timestamp: PropTypes.number.isRequired,
  target: PropTypes.string.isRequired,
  sign_count: PropTypes.number.isRequired,
  sign_status: PropTypes.oneOf(['open', 'closed']),
  trending: PropTypes.exact({
    sign_count_period: PropTypes.number,
  }).isRequired,
});

// An individual letter somone marked as open, functionally similar to a petition (CampaignTypeList)
const OpenLetterType = PropTypes.exact({
  messageId: PropTypes.string.isRequired,
  correlationId: PropTypes.string.isRequired,
  // We generally filter for public only
  // status: PropTypes.oneOf(Object.keys(LETTER_STATUSES)).isRequired,
  userId: PropTypes.string,
  subject: PropTypes.string,
  message: PropTypes.string.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  petitionerCode: PropTypes.string,
  sentTimestamp: PropTypes.number,
  shareCode: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(LETTER_STATUSES)),
  verifiedVoter: PropTypes.bool,
  target: PropTypes.shape({
    fullName: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    bioguideId: PropTypes.string,
  }),
  district: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    county: PropTypes.string,
  }),
  sender: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
  }),
});

const OpenLetterTypeList = PropTypes.exact({
  correlationId: PropTypes.string.isRequired,
  subject: PropTypes.string,
  message: PropTypes.string.isRequired, // We display this, trucated
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  verifiedVoter: PropTypes.bool.isRequired,
  sentTimestamp: PropTypes.number.isRequired,
  // shareCode: PropTypes.string.isRequired,
});

// TODO: Improve what's required here based on Themis schema
const DriveType = PropTypes.exact({
  userId: PropTypes.string.isRequired,
  shareCode: PropTypes.string.isRequired,
  shareImageUrl: PropTypes.string,
  driveType: PropTypes.oneOf(['CANDIDATE', 'REASON']).isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  totalSigns: PropTypes.number.isRequired,
  created: ISODateValidatorRequired,
  updated: ISODateValidatorRequired,
  election: PropTypes.shape({
    id: PropTypes.string,
    date: ISODateValidatorRequired,
    type: PropTypes.string,
    description: PropTypes.string,
  }),
  district: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  office: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    level: PropTypes.string,
    branch: PropTypes.string,
    chamber: PropTypes.string,
    isPartisan: PropTypes.string,
    type: PropTypes.string,
    seat: PropTypes.string,
    url: PropTypes.string,
  }),
  race: PropTypes.shape({
    id: PropTypes.string,
    year: PropTypes.number,
    type: PropTypes.string,
    numberOfSeats: PropTypes.number,
    isMarquee: PropTypes.bool,
    url: PropTypes.string,
    stageType: PropTypes.string,
    stageParty: PropTypes.string,
  }),
  candidate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    partyId: PropTypes.string,
    partyName: PropTypes.string,
    isIncumbent: PropTypes.bool,
    url: PropTypes.string,
  }),
});

const CampaignTypeList = PropTypes.exact({
  petition_code: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  sent_timestamp: PropTypes.number,
  target: PropTypes.string,
  sign_count: PropTypes.number.isRequired,
  sign_status: PropTypes.oneOf(['open', 'closed']),
  trending: PropTypes.exact({
    sign_count_period: PropTypes.number,
  }),
});

const AncillaryPersonType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  location: PropTypes.string,
});

// The `Campaign` element accepts to very different structures, and at some point
// it probalby makes sense to split that component rather than have two PropTypes
const CampaignTypeDriveList = PropTypes.exact({
  shareCode: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  signCount: PropTypes.number.isRequired,
  stateCode: PropTypes.string.isRequired,
  signStatus: PropTypes.string,
});

const CampaignTypePerson = PropTypes.shape({
  bioguideId: PropTypes.string.isRequired,
  districtId: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  officialType: PropTypes.oneOf(Object.keys(TARGET_TYPES_SHORT)),
  total: PropTypes.number.isRequired,
  intervalTotal: PropTypes.number.isRequired,
  party: PropTypes.string,
});

const CampaignTypeDriveRollupList = PropTypes.exact({
  officeName: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  signCount: PropTypes.number.isRequired,
});

// The old one still had the baggage of the old petition type
const NewCampaignTypeList = PropTypes.exact({
  shareCode: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  state: PropTypes.string,
  // created: PropTypes.number.isRequired,
  // target: PropTypes.string.isRequired,
  signCount: PropTypes.number.isRequired,
  signStatus: PropTypes.oneOf(['open', 'closed']),
  // trending: PropTypes.exact({
  //   sign_count_period: PropTypes.number,
  // }),
});

const MerchType = PropTypes.exact({
  _id: PropTypes.string.isRequired,
  image: SanityMerchImageType,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
});

const KeywordType = PropTypes.shape({
  keyword: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.object),
});

const PricePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  unitAmount: PropTypes.number.isRequired,
  formattedAmount: PropTypes.string.isRequired,
});

const PricesPropType = PropTypes.shape({
  month: PricePropType.isRequired,
  year: PricePropType.isRequired,
});

const CardPaymentMethodPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PAYMENT_TYPES.CARD, PAYMENT_METHOD_PAYMENT_TYPES.CARD])
    .isRequired,
  paymentMethod: PropTypes.bool,
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  expMonth: PropTypes.number.isRequired,
  expYear: PropTypes.number.isRequired,
  wallet: PropTypes.shape({
    type: PropTypes.string.isRequired,
    apple_pay: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  }),
  funding: PropTypes.oneOf(['credit', 'debit', 'prepaid', 'unknown']),
});

const BankAccountPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    PAYMENT_TYPES.BANK_ACCOUNT,
    PAYMENT_METHOD_PAYMENT_TYPES.US_BANK_ACCOUNT,
  ]).isRequired,
  accountHolderType: PropTypes.oneOf(['individual', 'company']).isRequired,
  accountType: PropTypes.oneOf(['checking', 'savings']).isRequired,
  last4: PropTypes.string,
  routingNumber: PropTypes.string,
  bankName: PropTypes.string,
});

const LinkPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PAYMENT_METHOD_PAYMENT_TYPES.LINK]).isRequired,
  paymentMethod: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  persistentToken: PropTypes.string,
});

const CashAppPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PAYMENT_METHOD_PAYMENT_TYPES.CASH_APP]).isRequired,
  paymentMethod: PropTypes.bool.isRequired,
});

const OfficialPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

const UserPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  verifiedVoter: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

const ThemisLetterPropType = PropTypes.shape({
  correlationId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  verifiedVoter: PropTypes.bool.isRequired,
  sentTimestamp: PropTypes.number.isRequired,
});

const PollPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  official: OfficialPropType.isRequired,
  pollType: PropTypes.oneOf(['APPROVAL', 'ELECTION']).isRequired,
  status: PropTypes.oneOf(['OPEN', 'CLOSED', 'DELETED']).isRequired,
  up: PropTypes.number.isRequired,
  down: PropTypes.number.isRequired,
  approval: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
});

export {
  BlogPostDetailType,
  BlogPostTileType,
  LetterType,
  NewspapersType,
  SiteStatsType,
  PetitionType,
  OpenLetterType,
  OpenLetterTypeList,
  DriveType,
  CampaignTypeList,
  CampaignTypePerson,
  AncillaryPersonType,
  CampaignTypeDriveList,
  CampaignTypeDriveRollupList,
  NewCampaignTypeList,
  MerchType,
  SanityMerchImageType,
  KeywordType,
  PricePropType,
  PricesPropType,
  CardPaymentMethodPropType,
  BankAccountPropType,
  LinkPropType,
  CashAppPropType,
  // Themis, generally
  OfficialPropType,
  UserPropType,
  ThemisLetterPropType,
  PollPropType,
};
